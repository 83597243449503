import React from "react";

function Card({ image, company, title, category, onCardClick }) {
  return (
    <div className="font-primary card p-2 cursor-pointer pb-3 " onClick={onCardClick}>
      <div className="relative w-full">
        <img
          className="h-56 w-full rounded-xl object-cover object-center"
          src={Array.isArray(image) ? image[0] : image}
          alt="image"
        />
        <div className="absolute inset-0 flex h-full w-full flex-col justify-end p-3">
          <div>
            <div className="badge rounded-full bg-white text-slate-800 ">
              {category}
            </div>
          </div>
        </div>
      </div>
      <div className="mx-2 mt-3">
        <div className="flex justify-between text-xs">
          <a className=" font-semibold text-slate-400 ">{company}</a>
        </div>
        <a className="mt-1.5 text-base font-semibold text-slate-700 line-clamp-1">
          {title}
        </a>
      </div>
    </div>
  );
}

export default Card;
