import logo from './logo.svg';
import './css/app.css';
import CardList from './partials/cardList';
import Hero from './partials/hero';
import About from './partials/about';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import Navbar from './partials/navbar';

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div>
      <Navbar/>
      <div  className='px-4 lg:px-8 pb-8 font-sans overflow-x-auto'>

      <Hero/>
      <CardList/>
      </div>
    </div>
  );
}

export default App;
