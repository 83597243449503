import React, { useState } from "react";
import Card from "./card";
import Modal from "./Modal";
import Web1 from "../images/web1.png";
import Web2 from "../images/web2.png";
import Mobile1 from "../images/mobile1.png";
import Mobile2 from "../images/mobile2.png";
import GGP from "../images/ggp.png";
import WebNewus from "../images/webNewus.png";
import PosterIG from "../images/posterIG.png";
import Compro from "../images/compro.png";
import DLH from "../images/ManualbookDLH.png";
import ALIN from "../images/alin.png";

const cardData = [
  {
    id: 1,
    image: [Web1, Web2],
    company: "PLN",
    title: "Teacherrenewable",
    category: "Website",
    description:
      "Merupakan aplikasi e-learning untuk internal karyawan PLN. Aplikasi ini dibangun menggunakan React Js sebagai frontend dan CI4 sebagai backend. Terdapat beberapa fitur diantaranya daftar course, penjadwalan course, catalog dan AI Chatbot.",
  },
  {
    id: 2,
    image: [Mobile1, Mobile2],
    company: "Dinas Pariwisata Lampung",
    title: "Siger Berjaya",
    category: "Mobile Apps",
    description:
      "Merupakan aplikasi informasi wisata yang ada di provinsi lampung. Aplikasi ini dibangun menggunakan Flutter sebagai frontend dan CI4 sebagai backend. Informasi yang ada di aplikasi ini meliputi tempat wisata, biaya masuk, rumah sakit terdekat, hotel terdekat, rumah makan terdekat dan kantor polisi terdekat dari masing-masing tempat wisata",
  },
  {
    id: 3,
    image: [GGP],
    company: "GGF",
    title: "Warehouse Management",
    category: "UI/UX Design",
    description:
      "Melakukan Analisis Sistem dan membuat Design aplikasi Warehouse Management (Mobile dan Web) menggunakan Figma.",
  },
  {
    id: 4,
    image: [WebNewus],
    company: "Newus Teknologi",
    title: "Company Profile Web",
    category: "UI/UX Design",
    description:
      "Merupakan Design Web Company Profile dibuat menggunakan Figma.",
  },
  {
    id: 5,
    image: [PosterIG],
    company: "Cinda Logika Grafia",
    title: "Social Media Post",
    category: "Design",
    description:
      "Design poster untuk kebutuhan posting media sosial perusahaan, dibuat menggunakan Photoshop.",
  },
  {
    id: 6,
    image: [Compro],
    company: "Newus Teknologi",
    title: "Company Profile",
    category: "Design",
    description: "Design Book Company Profile dibuat menggunakan Photoshop.",
  },
  {
    id: 7,
    image: [ALIN],
    company: "Newus Teknologi",
    title: "ALIN POS",
    category: "UI/UX Design",
    description:
      "Design aplikasi POS berbasis Website dan Mobile, dibuat menggunakan Figma.",
  },
  {
    id: 8,
    image: [DLH],
    company: "Dinas Lingkungan Hidup",
    title: "Manual Book",
    category: "Design",
    description:
      "Design Manual Book berpatokan pada aplikasi Sipesta senja Dinas Lingkungan Hidup, dibuat menggunakan Photoshop.",
  },
];

function CardList() {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedCard, setSelectedCard] = useState(null);

  const filteredCards =
    selectedCategory === "All"
      ? cardData
      : cardData.filter((card) => card.category === selectedCategory);

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleCloseModal = () => {
    setSelectedCard(null);
  };
  return (
    <div>
      <div className="font-primary text-center mb-12">
        <div className="h-px w-full bg-slate-200 rounded mb-8"></div>
        <div className="font-bold text-sm text-slate-800">Porfolio</div>
        <div className="mt-2 text-sm text-slate-800">
          Koleksi Beberapa Karya Terbaik
        </div>
      </div>
      <div className="overflow-x-auto">
        <div className="flex space-x-4 lg:justify-center">
          <button
            onClick={() => setSelectedCategory("All")}
            className={`flex text-sm px-5 py-2 rounded focus:outline-none hover:bg-slate-300 ${
              selectedCategory === "All"
                ? "bg-slate-800 text-white"
                : "bg-slate-150 text-slate-800"
            }`}
          >
            All
          </button>
          <button
            onClick={() => setSelectedCategory("UI/UX Design")}
            className={`flex text-sm px-5 py-2 rounded focus:outline-none hover:bg-slate-300 hover:text-slate-800 whitespace-nowrap ${
              selectedCategory === "UI/UX Design"
                ? "bg-slate-800 text-white "
                : "bg-slate-150 text-slate-800"
            }`}
          >
            UI/UX Design
          </button>
          <button
            onClick={() => setSelectedCategory("Website")}
            className={`flex text-sm px-5 py-2 rounded focus:outline-none hover:bg-slate-300 ${
              selectedCategory === "Website"
                ? "bg-slate-800 text-white"
                : "bg-slate-150 text-slate-800"
            }`}
          >
            Website
          </button>
          <button
            onClick={() => setSelectedCategory("Mobile Apps")}
            className={`flex text-sm px-5 py-2 rounded focus:outline-none hover:bg-slate-300 whitespace-nowrap ${
              selectedCategory === "Mobile Apps"
                ? "bg-slate-800 text-white"
                : "bg-slate-150 text-slate-800"
            }`}
          >
            Mobile Apps
          </button>
          <button
            onClick={() => setSelectedCategory("Design")}
            className={`flex text-sm px-5 py-2 rounded focus:outline-none hover:bg-slate-300 whitespace-nowrap ${
              selectedCategory === "Design"
                ? "bg-slate-800 text-white"
                : "bg-slate-150 text-slate-800"
            }`}
          >
            Design
          </button>
        </div>
      </div>

      <div className="mt-4 grid grid-cols-1 gap-4 sm:mt-5 sm:grid-cols-4 sm:gap-5 lg:mt-6 lg:gap-6">
        {filteredCards.map((card) => (
          <Card
            key={card.id}
            image={card.image}
            company={card.company}
            description={card.description}
            title={card.title}
            category={card.category}
            onCardClick={() => handleCardClick(card)}
          />
        ))}
      </div>
      <Modal
        isOpen={!!selectedCard}
        onClose={handleCloseModal}
        cardDetails={selectedCard}
      />
    </div>
  );
}

export default CardList;
