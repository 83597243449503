import React from "react";
import LestTalk from "./letstalk";

const Navbar = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "7%",
        position: "fixed",
        top: "0",
        zIndex: 99,
        backgroundColor: "rgba(255, 255, 255)",
        alignItems: "center",
        boxShadow: "0px 4px 6px rgba(175,175,175, 0.1)",
      }}
    >
      <div className="px-4 lg:px-8 h-full flex justify-between items-center">
        <div className="font-black text-xl">Fau.</div>
        <LestTalk />
      </div>
    </div>
  );
};

export default Navbar;
